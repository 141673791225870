import styled, { css } from "styled-components";
import { Link } from "gatsby";

import {
  DESKTOP_1200,
  MOBILE_500,
  TABLET_992,
  TABLET_600,
  MOBILE_380,
} from "../../styles/sizes";

export const Container = styled.div`
  width: 100%;
  height: auto;
  @media (max-width: ${TABLET_992}px) {
    display: flex;
    justify-content: center;
    height: auto;
  }
`;

export const GridContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 3%;
  margin-top: 30px;
  min-height: 570px;

  @media (max-width: ${DESKTOP_1200}px) {
    grid-column-gap: 2%;
  }

  @media (max-width: ${TABLET_992}px) {
    margin-bottom: 90px;
    min-height: 580px;
  }

  @media (max-width: ${TABLET_992}px) {
    grid-template-columns: 1fr;
    margin-top: 0px;
    //margin-bottom: 80px;
    width: 60%;
    min-height: none;
  }

  @media (max-width: ${TABLET_600}px) {
    width: 70%;
  }

  @media (max-width: ${MOBILE_500}px) {
    width: 100%;
  }
`;

export const CardContainer = styled.div<IdivProps>`
  .card {
    height: 70%;
    display: flex;
    flex-direction: column;
    background-color: #cdd3d765;
    border-radius: 25px;
    padding: 50px 30px;
    border: 15px solid white;
    margin-top: 30px;
    transition-duration: 0.5s;
    position: relative;
    align-items: center;

    @media (max-width: ${TABLET_992}px) {
      ${(props) =>
        props.isClickedMobile
          ? css`
              .BlackAndWhiteIllustration {
                display: none;
              }
              .ColorIllustration {
                display: block;
              }
              .HoverText {
                opacity: 1;
                height: auto;
                transition-duration: 1s;
              }
              .Text {
                opacity: 0;
                height: 0;
                overflow: hidden;
              }
            `
          : css`
              .Text {
                opacity: 1;
                height: auto;
                transition-duration: 0.5s;
              }
            `}
    }
    @media (max-width: ${MOBILE_380}px) {
      padding: 50px 20px;
    }
  }

  @media (min-width: ${TABLET_992}px) {
    &:hover {
      .card {
        .HoverText {
          opacity: 1;
          height: auto;
          transition-duration: 0.5s;
        }
        .Text {
          display: none;
        }
        .Title {
          transition-duration: 0.5s;
          margin-top: 18px;
        }
      }
    }
  }
`;

export const ServiceCard = styled.div<IdivProps>``;
export const ColorIllustration = styled.img`
  width: 100%;
  max-height: 100px;

  @media (max-width: ${TABLET_992}px) {
    max-height: 191px;
    max-width: 100px;
  }
`;

export const Title = styled.div`
  color: #000000;
  font: normal 800 34px/42px "Montserrat";
  margin-top: 36px;
  font-variation-settings: "wght" 600;

  @media (max-width: ${DESKTOP_1200}px) {
    font: normal normal bold 26px/36px "Montserrat";
  }

  @media (max-width: 830px) {
    font: normal normal bold 23px/36px "Montserrat";
    margin-top: 26px;
  }

  @media (max-width: ${TABLET_992}px) {
    font: normal normal bold 34px/42px "Montserrat";
  }
`;

export const Text = styled.div`
  font: normal normal 300 14px/26px "Montserrat";
  color: #0d0d0d;
  margin-top: 26px;

  @media (max-width: 830px) {
    margin-top: 15px;
  }
`;

export const HoverText = styled.div`
  font: normal normal 300 20px/26px "Montserrat";
  color: #0d0d0d;
  transition: opacity 0.5s ease-out;
  opacity: 0;
  height: 0;
  overflow: hidden;
  padding-top: 20px;

  @media (max-width: ${TABLET_992}px) {
    padding-top: 0;
  }
`;

export const SeparatorCircle = styled.div`
  border-radius: 50%;
  height: 6px;
  width: 6px;
  margin: 0 5px;
  background-color: #ff0000;
  vertical-align: middle;
  display: inline-block;
`;

export const NoWrapDiv = styled.div`
  white-space: nowrap;
  display: inline-block;
`;

export const MoreIcon = styled.img<IdivProps>`
  display: none;
  transition: all 0.3s ease-in-out;
  @media (max-width: ${TABLET_992}px) {
    display: block;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 73px;
    height: 73px;
  }
`;

export const Redirect = styled(Link)`
  text-decoration: none;
  display: flex;
`;

interface IdivProps {
  isClickedMobile?: boolean;
  isClicked?: boolean;
}
