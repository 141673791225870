import React, { useState } from "react";

import Design from "./assets/design.svg";
import Software from "./assets/software.svg";
import Growth from "./assets/growth.svg";
import MoreImg from "./assets/more.svg";
import CloseImg from "./assets/close.svg";

import OnViewTrigger from "../../components/OnViewTrigger/Index";

import {
  GridContainer,
  Container,
  NoWrapDiv,
  ServiceCard,
  CardContainer,
  Title,
  Text,
  HoverText,
  SeparatorCircle,
  ColorIllustration,
  MoreIcon,
  Redirect,
} from "./styles";

export const Services: React.FC = () => {
  const [isActiveCard1, setIsActiveCard1] = useState<boolean>(false);
  const [isActiveCard2, setIsActiveCard2] = useState<boolean>(false);
  const [isActiveCard3, setIsActiveCard3] = useState<boolean>(false);

  const [isHiden, setIsHiden] = useState<boolean>(false);
  return (
    <Container>
      <GridContainer>
        <OnViewTrigger
          onView={() => setIsActiveCard1(true)}
          TriggerPosition={0.3}
          maxTriggerPosition={0.7}
          finishOnView={() => setIsActiveCard1(false)}
        >
          <CardContainer isClickedMobile={isActiveCard1}>
            <ServiceCard className="card" isClickedMobile={isActiveCard1}>
              <ColorIllustration className="ColorIllustration" src={Design} />
              <Title className="Title">
                Design & <br />
                Creativity
              </Title>
              <Text className="Text">
                UX{" "}
                <NoWrapDiv>
                  <SeparatorCircle /> UI
                </NoWrapDiv>
                <NoWrapDiv>
                  <SeparatorCircle /> Product Design{" "}
                </NoWrapDiv>
                <br />
                <NoWrapDiv>
                  <SeparatorCircle /> Mobile First{" "}
                </NoWrapDiv>
                <NoWrapDiv>
                  <SeparatorCircle /> Prototyping{" "}
                </NoWrapDiv>
                <NoWrapDiv>
                  <SeparatorCircle /> Motion Design{" "}
                </NoWrapDiv>
                <NoWrapDiv>
                  <SeparatorCircle /> Brand Identity{" "}
                </NoWrapDiv>
                <NoWrapDiv>
                  <SeparatorCircle /> Co-Creation
                </NoWrapDiv>
                <NoWrapDiv>
                  {" "}
                  <SeparatorCircle /> Display Art
                </NoWrapDiv>
              </Text>
              <HoverText className="HoverText">
                Create a strong foundation for your business. Enhance your brand
                voice and architecture your product positioning. We’ll lead your
                ideas to the next level.
              </HoverText>
            </ServiceCard>
          </CardContainer>
        </OnViewTrigger>
        <OnViewTrigger
          onView={() => setIsActiveCard2(true)}
          TriggerPosition={0.3}
          maxTriggerPosition={0.7}
          finishOnView={() => setIsActiveCard2(false)}
        >
          <CardContainer isClickedMobile={isActiveCard2}>
            <ServiceCard className="card" isClickedMobile={isActiveCard2}>
              <ColorIllustration className="ColorIllustration" src={Software} />
              <Title className="Title">
                Software <br />{" "}
                <span style={{ whiteSpace: "nowrap" }}>Development</span>
              </Title>
              <Text className="Text">
                <NoWrapDiv>Web & Mobile</NoWrapDiv>
                <NoWrapDiv>
                  <SeparatorCircle className="SeparatorCircle" /> Cloud
                  Architecture
                </NoWrapDiv>
                <NoWrapDiv>
                  <SeparatorCircle /> Agile Methodologies
                </NoWrapDiv>
                <NoWrapDiv>
                  <SeparatorCircle /> Quality Assurance
                </NoWrapDiv>
                <NoWrapDiv>
                  <SeparatorCircle /> Frontend
                </NoWrapDiv>
                <NoWrapDiv>
                  <SeparatorCircle /> Backend
                </NoWrapDiv>
                <NoWrapDiv>
                  <SeparatorCircle /> CI/CD
                </NoWrapDiv>
              </Text>
              <HoverText className="HoverText">
                Engineer your brand digital presence with{" "}
                <NoWrapDiv>user-friendly</NoWrapDiv> and reliable software
                solutions, tailored to empower your business.
              </HoverText>
            </ServiceCard>
          </CardContainer>
        </OnViewTrigger>
        <OnViewTrigger
          onView={() => setIsActiveCard3(true)}
          TriggerPosition={0.3}
          maxTriggerPosition={0.7}
          finishOnView={() => setIsActiveCard3(false)}
        >
          <CardContainer isClickedMobile={isActiveCard3}>
            <ServiceCard className="card" isClickedMobile={isActiveCard3}>
              <ColorIllustration className="ColorIllustration" src={Growth} />
              <Title className="Title">
                Growth & <br /> Marketing
              </Title>
              <Text className="Text">
                <NoWrapDiv>Social Media </NoWrapDiv>
                <NoWrapDiv>
                  <SeparatorCircle /> SEO{" "}
                </NoWrapDiv>
                <NoWrapDiv>
                  <SeparatorCircle /> SEM{" "}
                </NoWrapDiv>
                <NoWrapDiv>
                  <SeparatorCircle /> Paid User Acquisition{" "}
                </NoWrapDiv>
                <NoWrapDiv>
                  <SeparatorCircle /> Social Listening{" "}
                </NoWrapDiv>
                <NoWrapDiv>
                  <SeparatorCircle /> Growth Consulting{" "}
                </NoWrapDiv>
                <NoWrapDiv>
                  <SeparatorCircle /> Analytics & Insights
                </NoWrapDiv>
                <NoWrapDiv>
                  <SeparatorCircle /> Brand Engagement
                </NoWrapDiv>
              </Text>
              <HoverText className="HoverText">
                Increase awareness, generate engagement and drive sales to your
                business with impactful strategies and{" "}
                <NoWrapDiv>high-quality</NoWrapDiv> content campaigns.
              </HoverText>
            </ServiceCard>
          </CardContainer>
        </OnViewTrigger>
      </GridContainer>
    </Container>
  );
};
